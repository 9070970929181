import { connectRouter } from "connected-react-router"
import { combineReducers } from "redux"
import kitties from 'App/reducer'

const reducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    kitties
  })

export default reducer;
