import React, { useEffect, useState } from 'react'
import { Route } from 'react-router'
import axios from 'axios'
import io from 'socket.io-client'
import Homepage from 'pages/Homepage'
import FamilyTree from 'pages/FamilyTree'
import Profile from 'pages/Profile'
import KittyHats from 'pages/KittyHats'
import Report from 'pages/Report'
import Audit from 'pages/Audit'
import Total from 'components/Total'
import ScrollTop from 'components/ScrollTop'
import Chat from 'components/Chat'
import Confetti from 'components/Confetti'
import CryptoKittiesContract from 'contracts/cryptokitties'
import Contract from 'contracts/kitty-hats'
import Connect from 'components/Connect'
import KittyHatsMarketplace from 'pages/KittyHatsMarketplace'
import Search from 'components/KittiesWithSearch'

const { REACT_APP_END_POINT } = process.env

const AppView = ({ loggedIn, handleSignIn, handleSignOut, web3, user, token, checkToken }) => {
	const [socket, setSocket] = useState(undefined)
	const [hats, setHats] = useState(undefined)
	const [allHatEvents, setAllHatEvents] = useState(undefined)
	const [total, setTotal] = useState(undefined)
	const [catsWithHats, setCatsWithHats] = useState(undefined)
	const [searchables, setSearchables] = useState(undefined)
	const [showCelebration, setShowCelebration] = useState(false)
	const [newPurchaseKitty, setNewPurchaseKitty] = useState(undefined)

	useEffect(() => {
		const getSearchables = async () => {
			const { data } = await axios(`${REACT_APP_END_POINT}/cryptokitties/kitties/cattributes`)
			setSearchables(data)
		}
		getSearchables()
	}, [])

	useEffect(() => {
		if (hats === undefined) {
			axios.get(`${REACT_APP_END_POINT}/kitty-hats`).then(({ data }) => {
				axios.get(`${REACT_APP_END_POINT}/kitty-hats-events`).then(({ data: { hats: _hats } }) => {
					setHats(data)
					setAllHatEvents(_hats)
					const buyItemAndApplyHats = _hats.filter(hat => hat.applied && !hat.removed)
					const uniqueTokenIds = [...new Set(buyItemAndApplyHats.map(({ tokenId }) => tokenId))]
					setCatsWithHats(uniqueTokenIds)
				}).catch(e => console.log(e))
			}).catch(e => console.log(e))
		}
	}, [])

	useEffect(() => {
		const handleProgress = (progress) => setTotal(progress.Birth);
	  
		// Close the existing socket when the component unmounts or when the token changes
		const closeSocket = () => {
		  if (socket) {
			socket.off('progress', handleProgress);
			socket.close();
			setSocket(null);
		  }
		};
	  
		// Create a new socket connection when the component mounts or when the token changes
		const createSocket = () => {
		  const _socket = io(REACT_APP_END_POINT, {
			transports: ['websocket'],
			auth: { token },
		  });
	  
		  _socket.on('connect_error', (err) => console.log(`connect_error due to ${err}`));
	  
		  _socket.on('connect', () => {
			const token = _socket.auth.token;
			setSocket(_socket);
		  });
	  
		  _socket.on('disconnect', () => {
			console.log('Socket disconnected');
			setSocket(null);
		  });
	  
		  _socket.on('progress', handleProgress);
		  _socket.emit('getProgress');
		};
	  
		// Close the existing socket and create a new one when the component mounts or when the token changes
		closeSocket();
		createSocket();
	  
		// Clean up when the component unmounts
		return () => {
		  closeSocket();
		};
	  }, [token]);

	const handlePurchase = async (tokenId, value) => {
		try {
			if (loggedIn) {
				const instance = new web3.eth.Contract(
					CryptoKittiesContract.Sale.abi,
					CryptoKittiesContract.Sale.addr
				)
				const sale = await instance.methods.bid(tokenId).send({ from: loggedIn, value })
				return true
			} else {
				handleSignIn()
				return false
			}
		} catch (e) {
			console.log(e)
			return false
		}
	}

	const handleHatPurchase = async (item, value) => {
        const _item = item.replace('Item', '')
        try {
            if (loggedIn) {
                const instance = new web3.eth.Contract(Contract.Core.abi, Contract.Core.addr)
				console.log(_item)
                const checkItem = await instance.methods.getItem(_item).call()
                const gas = await instance.methods.buyItem(_item, '1').estimateGas({ from: loggedIn, value })
                const sale = await instance.methods.buyItem(_item, '1').send({ from: loggedIn, value, gas })
                setShowCelebration(true)
				return true
            } else {
				handleSignIn()
				return false
			}
        } catch (e) {
            console.log(e)
			return false
        }
    }

    const handleHatPurchaseAndApply = async (tokenId, item, value) => {
        const _item = item.replace('Item', '')
        try {
            if (loggedIn) {
                const instance = new web3.eth.Contract(Contract.Core.abi, Contract.Core.addr)
                const checkItem = await instance.methods.getItem(_item).call()
				try {
					const gas = await instance.methods.buyItemAndApply(_item, tokenId).estimateGas({ from: loggedIn, value: checkItem[1] })
					const sale = await instance.methods.buyItemAndApply(_item, tokenId).send({ from: loggedIn, value: checkItem[1], gas })
					setShowCelebration(true)
					return true
				} catch (e) {
					console.log(e)
				}
            } else {
				handleSignIn()
				return false
			}
        } catch (e) {
            console.log(e)
			return false
        }
    }

	useEffect(() => {
		let timeoutId
		if (showCelebration) {
			timeoutId = setTimeout(() => setShowCelebration(false), 2000)
		}
		return () => {
			clearTimeout(timeoutId)
		}
	}, [showCelebration])

	return (
		<>

			{showCelebration && <Confetti usePortal />}
			<Total {...{ total }} />
			<ScrollTop>
				<Route exact path="/" render={() => <Homepage {...{ hats, allHatEvents, loggedIn, web3, searchables, socket, handleHatPurchase, handleHatPurchaseAndApply }} />} />
				<Route path="/kitty/:id" render={props => <FamilyTree {...props} {...{ loggedIn, handlePurchase, searchables }} />} />
				<Route path="/profile/:profile" render={props => <Profile {...props} {...{ loggedIn, handlePurchase, searchables, token, handleSignIn, user, checkToken }} />} />
				<Route path="/kitty-hats" render={props => <KittyHats allHats={hats} {...{ allHatEvents, catsWithHats, handlePurchase, searchables }} /> } />
				<Route path="/kitty-hats-marketplace" render={() => <KittyHatsMarketplace {...{ hats, allHatEvents, loggedIn, searchables, web3, handleHatPurchase, handleHatPurchaseAndApply }} /> } />
				<Route path={'/search'} render={() => searchables ? <Search {...{ searchables, handlePurchase }} account={{ wallet: loggedIn }} /> : ''} />
				<Route path={'/report'} render={() => <Report {...{ socket }} />} />
				<Route path={'/audit'} render={() => <Audit {...{ socket }} />} />
				<Chat  {...{ loggedIn, socket, user, catsWithHats, token }} />
				<Connect {...{ loggedIn, handleSignIn, handleSignOut }} />
			</ScrollTop>
		</>
	)
}

export default AppView