import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import axios from 'axios'
import Kitty from 'components/Kitty'
import Pagination from 'components/Pagination'
import * as Styled from './KittyHats.style'
const { REACT_APP_END_POINT } = process.env

const KittyHats = ({ allHats, allHatEvents, catsWithHats, handlePurchase }) => {
    const [kitties, setKitties] = useState(undefined)
    const [hats, setHats] = useState(undefined)
    const [total, setTotal] = useState(undefined)
    const [search, setSearch] = useState({ includeAll: false, page: 1 })
    const history = useHistory()
    useEffect(() => {
        const getCatsWithHats = async () => {
            try {
                setKitties(undefined)
                setHats(undefined)
                setTotal(undefined)
                window.scrollTo({
                    top: 0,
                    left: 0,
                    behavior: 'smooth',
                });
                const include = search.includeAll ? 'include=sale,sire,other' : 'include=sale'
                const orderBy = search.includeAll ? '' : '&orderBy=current_price'
                const { data: { kitties, total } } = await axios.get(`${REACT_APP_END_POINT}/cryptokitties/kitties?${include}&search=hats:true${orderBy}&page=${search.page}`)
                const ids = kitties.map(({ tokenId }) => tokenId)
                const { data: { hats }} = await axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids.join(',')}&skip=${(search.page - 1) * 20}`)
                const { data: { kitties: ckData } } = await axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids.join(',')}&limit=20&skip=${(search.page - 1) * 20}`)
                const _kitties = kitties.map(kitty => ({ ...ckData.find(ck => ck.id === kitty.tokenId), ...kitty }))
                setHats([...hats])
                setKitties(_kitties)
                setTotal(total)
            } catch (error) {
                console.log(error)
            }
        }
        if (catsWithHats) {
            getCatsWithHats()
        }
    }, [catsWithHats, search])

    const getEvent = (events, event) => events.filter(({ itemName }) => event.itemName === itemName)
    const getEdition = (events, event) => `${getEvent(allHatEvents, event).indexOf(event) + 1} of ${allHats.find(({ contract }) => contract.replace('Item','') === event.itemName).amount}`
    const formatDate = (timestamp) => {
        const date = new Date(timestamp * 1000)
        return new Intl.DateTimeFormat('en-US', { day: 'numeric', month: 'long', year: 'numeric' }).format(date)
    }
    return (
        <>
            <Styled.Div>
                <img src={'/images/kitty-hats/logo.png'} alt={'Kitty Hats'} />
                <h2>{'Kitty Hats'}</h2>
                <p>{'The OG CryptoKitties community hat project from 2018 is back with a temporary home at kitty.family'}</p>
                <p>{'If you already have a kitty and just need a hat then'} <Link to={'/kitty-hats-marketplace'}>{'click here'}</Link></p>
                <div>
                    <div>Total: {total === undefined ? '...' : total}</div>
                    <div>
                        <label>For Sale Only</label>
                        <input type={'checkbox'} checked={!search.includeAll} onChange={() => setSearch({ ...search, includeAll: !search.includeAll, page: 1 })} />
                    </div>
                </div>
                
            </Styled.Div>
            <Styled.Grid>
                {kitties && hats && allHatEvents && allHats && kitties.map((kitty, i) => {
                    return (
                        <div key={i}>
                            <Kitty {...{ kitty, handlePurchase }} c2aPosition='top' showMewts={true} hats={hats.filter(({ tokenId }) => tokenId === kitty.tokenId)} showInfo={false} bgColor={kitty.color} getInfo={() => history.push(`/kitty/${kitty.tokenId}`)} />
                            {hats.filter(({ tokenId }) => tokenId === kitty.tokenId).map((hat, i) => {
                                return allHatEvents.filter(({ tokenId }) => tokenId === kitty.tokenId).map((_event, q) => {
                                    return i === q && (
                                        <div key={i}>
                                            {i === 0 && <h3><Link to={`/kitty/${kitty.tokenId}`}>#{kitty.tokenId}{kitty.name && ` - ${kitty.name}`}</Link></h3>}
                                            <h3>🎩  {_event.itemName}</h3>
                                            <h4>{getEdition(allHatEvents, _event)}</h4>
                                            <p>{formatDate(_event.appliedTimestamp)}</p>
                                        </div>
                                    )
                                })
                            })}
                        </div>
                    )
                })}
                {total ? (
                    <Pagination
                        showPages={true}
                        currentPage={search.page}
                        totalPages={Math.ceil(total / 20)}
                        maxPages={10}
                        onChange={page => setSearch({ ...search, page })}
                    />
                ) : (
                    ''
                )}
            </Styled.Grid>
            
        </>
    )
}

export default KittyHats