import React from 'react'
import KittyHats from 'components/KittyHats'
import * as Styled from './KittyHatsMarketplace.style'

const KittyHatsMarketplace = ({ hats, allHatEvents, loggedIn, searchables, web3, handleHatPurchase, handleHatPurchaseAndApply }) => {
  return (
    <Styled.Div>
        <img src={'/images/kitty-hats/logo.png'} alt={'Kitty Hats'} />
        <h2>Kitty Hats</h2>
        <p>{'What could be better than a cat with a hat? Maybe a cat with an OG af Kitty Hat from 2018? 🙀'}</p>
        <p>{'There are 2 options - either buy the Kitty Hat and apply onto a CryptoKitty you own - or buy the hat and apply it later'}</p>
        <p>{'A CryptoKitty can have more than one hat. If you then remove the hat it is gone forever!'}</p>
        <KittyHats {...{ hats, allHatEvents, loggedIn, searchables, web3 }}  handlePurchase={handleHatPurchase} handlePurchaseAndApply={handleHatPurchaseAndApply}  />
    </Styled.Div>
  )
}

export default KittyHatsMarketplace 
