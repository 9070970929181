import styled from 'styled-components'
import { Main as _Main } from 'style'
import { fontSize } from 'style/config'

export const Main = styled(_Main)`
  max-width: 1792px;
  margin: 0 auto;
  > h2 { /* Featured Kitties */
    margin: 100px 0 30px;
    font-size: ${fontSize['lg']};
    &:last-of-type {
      margin: 0 0 30px;
    }
  }
  > img {
    margin: 100px 0 4px;
  }
  > h4 {
    margin: 24px 5%;
  }
  > p {
    padding: 140px 5% 0;
    @media (min-width: 700px) {
      padding: 0;
      max-width: 800px;
      margin: 0 auto;
    }
  }
  > img {
    width: 40px;
  }
`
