import { useEffect, useState } from 'react'
import axios from 'axios'
import { useHistory } from 'react-router-dom'
import Kitty from 'components/Kitty'
import * as Styled from './FeaturedKitties.style'

const { REACT_APP_END_POINT } = process.env
const FEATURED_KITTIES = [2023378,556041,540365,332858,407629]

const FeaturedKitties = () => {
  const history = useHistory()
  const [kitties, setKitties] = useState(undefined)
  const [hats, setHats] = useState([])
  useEffect(() => {
    if (kitties === undefined) {
      const ids = FEATURED_KITTIES.join(',')
      axios.get(`https://api.cryptokitties.co/v2/kitties?search=id:${ids}`).then(({ data: { kitties } }) => {
        axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids}`).then(({ data: { hats }}) => {
          const sortedKitties = kitties.sort((a, b) => ids.indexOf(a.id.toString()) - ids.indexOf(b.id.toString()))
          setKitties(sortedKitties)
          setHats(hats)
        })
      })
    }
  }, [kitties])

  return (
    <Styled.Div>
      {kitties && kitties.map((kitty, i) =>
          <Kitty key={i} {...{ kitty }} hats={hats.filter(({ tokenId }) => tokenId === kitty.id)} showInfo={false} getInfo={() => history.push(`/kitty/${kitty.id}`)} bgColor={kitty.color} />
      )}
    </Styled.Div>
  )
}

export default FeaturedKitties
