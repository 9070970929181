import { useHistory } from 'react-router-dom'
import loading from 'svg/loading.svg'
import * as Styled from './Total.style'

const Total = ({ total }) => {
  const history = useHistory()

  return (
    <Styled.H3>Total cryptokitties: {total
      ? <span onClick={() => history.push(`/kitty/${total}`)}>{total}</span>
      : <img src={loading} alt={''} />}
    </Styled.H3>
  )

}
  

export default Total
