import { useEffect, useState } from 'react'
import axios from 'axios'
import { Link } from 'react-router-dom'
import logo from 'svg/logo.svg'
import KittyPfp from 'components/KittyPfp'
import * as Styled from './Messages.style'

const formatDate = (date) => {
  const d = new Date(date);
  const day = d.toLocaleDateString('en-GB', { day: 'numeric' });
  const month = d.toLocaleDateString('en-GB', { month: 'short' });
  const year = d.toLocaleDateString('en-GB', { year: 'numeric' });
  const time = d.toLocaleTimeString('en-GB', { hour: '2-digit', minute: '2-digit' });
  return `${day} ${month} ${year} ${time}`;
};

const { REACT_APP_END_POINT } = process.env

const Messages = ({ socket }) => {
  const [messages, setMessages] = useState([])
  const [profiles, setProfiles] = useState(undefined)
  const [hats, setHats] = useState(undefined)
  const [results, setResults] = useState(undefined)

  useEffect(() => {
    if (socket) {
      const handleMessages = (data) => {
        setMessages([...data].reverse())
      }
  
      socket.on('messages', handleMessages)
  
      socket.emit('getMessages')
  
      return () => {
        socket.off('messages', handleMessages)
      }
    }
  }, [socket])

  useEffect(() => {
    const fetchProfiles = async () => {
      if (messages.length > 0 && profiles === undefined) {
        const uniqueAddresses = [...new Set(messages.map(({ account }) => account && account.toLowerCase()))].filter(Boolean)
        if (uniqueAddresses.length > 0) {
          try {
            const response = await axios.get(`${REACT_APP_END_POINT}/account?accounts=${uniqueAddresses}`)
            const uniqueIds = [...new Set(response.data.map(({ avatar }) => avatar > -1 && avatar))].filter(Boolean)
            setProfiles([...new Set(response.data.map(({ avatar, address, displayName }) => avatar > -1 && ({ avatar, address, displayName })))].filter(Boolean))
            const { data: { kitties } } = await axios.get(`${REACT_APP_END_POINT}/cryptokitties/kitties?search=id:${uniqueIds.join(',')}`)
            const ids = kitties.map(({ tokenId }) => tokenId).join(',')
            axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids}&limit=20`).then(async ckResult => {
                const { data: { kitties: ckData } } = ckResult
                const _kitties = kitties.map(kitty => {
                    return { ...ckData.find(ck => ck.id === kitty.tokenId), ...kitty };
                });
                const { data: { hats } } = await axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids}`)
                setHats([...hats])
                setResults(_kitties)
            })
          } catch (error) {
            console.error('Error fetching profiles:', error)
          }
        }
      }
    };

    fetchProfiles()
  }, [messages, profiles])

  return (
    <Styled.Div>
      {profiles && messages.map(({ account, createdAt, message }, i) =>
        <div key={i}>
          <Link to={`/profile/${account}`} onClick={e => e.preventDefault()}>
              {results && (
                profiles.find(({ address }) => address === account) ? (
                  <KittyPfp
                    getInfo={() => console.log('blob')}
                    kitty={results.find(({ tokenId }) => tokenId === profiles.find(({ address }) => address === account).avatar)}
                    hats={[...hats.filter(({ tokenId }) => tokenId === profiles.find(({ address }) => address === account).avatar)]}
                    bgColor={results.find(({ tokenId }) => tokenId === profiles.find(({ address }) => address === account).avatar).color}
                  />
                ) : (
                  <img src={logo} alt={''} />
                )
              )}
          </Link>
          <div>
            <div><Link to={`/profile/${account}`}>{profiles.find(({ address }) => address === account) && profiles.find(({ address }) => address === account).displayName ? profiles.find(({ address }) => address === account).displayName : account}</Link></div>
            <div>{message}</div>
            <div>- {formatDate(createdAt)}</div>
          </div>
        </div>
      )}
    </Styled.Div>
  )
}

export default Messages
