import {
  REQUEST_KITTIES,
  RECEIVE_KITTIES,
  ERROR_KITTIES
} from './actions'

const initialState = {
  fetchingKitties: true,
  kitties: [],
  error: false
}

const kittiesReducer = function kittiesReducer (state = initialState, action) {
  switch (action.type) {
    case REQUEST_KITTIES:
      return {
        ...state,
        fetchingKitties: true
      }
    case RECEIVE_KITTIES:
      return {
        ...state,
        kitties: action.payload,
        fetchingKitties: false
      }
    case ERROR_KITTIES:
      return {
        ...initialState,
        error: action.payload
      }

    default:
      return state
  }
}

export default kittiesReducer
