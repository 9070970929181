import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import axios from 'axios'
import Kitty from 'components/Kitty'
import { ReactComponent as Close } from 'svg/close.svg'
import { ReactComponent as Search } from 'svg/search.svg'
import KittyGenes from 'components/KittyGenes'
import Jewels from 'components/Jewels2'
import { cooldowns } from 'utils'
import * as Styled from './KittyModal.style'

const Modal = ({ kitty, hats, handlePurchase, onClose, currentKittyId }) => {

    const [showDetails, setShowDetails] = useState(false)
    const [offspring, setOffspring] = useState([])
    const [bio, setBio] = useState(undefined)
  
    useEffect(() => {
        axios.get(`https://api.cryptokitties.co/v3/kitties/${kitty.tokenId}`)
        .then(({ data: { bio }}) => {
          setBio(bio)
            // const _kitties = data.kitties.map(kitty => {
            //   return { ...ckData.find(ck => ck.id === kitty.tokenId), ...kitty };
            // });
            // axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids}`).then(({ data: { hats }}) => {
            //   setHats(hats)
            //   setKitties(_kitties);
            // }).catch(error => console.error('Error fetching Hats data:', error))
        }).catch(error => console.error('Error fetching CryptoKitties data:', error))
    }, [kitty])
      
    const modalRef = useRef(null)
    const handleOverlayClick = (event) => modalRef.current && event.target === modalRef.current && onClose()
    const handleKeyDown = (event) =>  event.key === 'Escape' && onClose() // Attach a keydown event listener to close the modal on pressing the "Escape" key
  
    useEffect(() => {
        document.addEventListener('click', handleOverlayClick)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('click', handleOverlayClick);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose])
  
    const getPBCattributes = (_kitty) => {
      let pb = 0;
      [0,4,8,12,16,20,24,28,32,36,40,44].forEach(i => {
        if (_kitty[`g${i}pb`]) {
          pb++
        }
      })
      return pb
    }
  
    const getMewtationsAmount = (_kitty, slot, level) => _kitty[`sl${slot}m${level}`]
  
    return (
        <Styled.Modal ref={modalRef}>
            <div className={kitty.color}>
                <Close onClick={onClose} />
                <div>
                  <Kitty {...{ kitty, hats, handlePurchase }} showInfo={false} c2aPosition='top' getInfo={() => undefined} />
                  <Jewels {...{ kitty }} displayType={'mewtations'} />
                  <h2>#{kitty.tokenId}{kitty.name && ` - ${kitty.name}`}</h2>
                  <h3>Gen{kitty.gen} - {cooldowns[kitty.status.cooldown_index]}</h3>
                  <Jewels {...{ kitty }} displayType={'family-jewels'} />
                  {bio && <p className={'bio'} dangerouslySetInnerHTML={{ __html: bio.replace(kitty.name, `<b>${kitty.name}</b>`)}} />}
                  <div className={'genes'}>
                    <code>{kitty.genes}</code>
                    <Search role={'button'} onClick={() => {
                      const _showDetails = !showDetails
                      setShowDetails(_showDetails)
                    }} />
                  </div>
                  {showDetails && <KittyGenes {...{ kitty }} showBinaryGenes={true} defaultOpen={false} />}
                  {getPBCattributes(kitty) > 0 && <h4>Pure Bred Cattributes: {getPBCattributes(kitty)}</h4>}
                  <h4>
                    <span>{getMewtationsAmount(kitty, '0', '0') > 0 && `Base: ${getMewtationsAmount(kitty, '0', '0')} `}</span>
                    <span>{getMewtationsAmount(kitty, '0', '1') > 0 && `M1: ${getMewtationsAmount(kitty, '0', '1')} `}</span>
                    <span>{getMewtationsAmount(kitty, '0', '2') > 0 && `M2: ${getMewtationsAmount(kitty, '0', '2')} `}</span>
                    <span>{getMewtationsAmount(kitty, '0', '3') > 0 && `M3: ${getMewtationsAmount(kitty, '0', '3')} `}</span>
                    <span>{getMewtationsAmount(kitty, '0', '4') > 0 && `M4: ${getMewtationsAmount(kitty, '0', '4')} `}</span>
                  </h4>
                  <h4>Owners: {kitty.owners.length} - Current Owner: {kitty.owner}</h4>
                  
                  
                  <h4>Offspring: {kitty.offspring}</h4>
                  {kitty.offspring > 0 && <p>{kitty.offspringIds.map((k, i) => {
                    return k.toString() === currentKittyId
                      ? <Link key={i} to={``} onClick={e => {
                          e.preventDefault();
                          onClose();
                        }}>{`-#${k} `}</Link>
                      : <Link key={i} to={`/kitty/${k}`}>{`-#${k} `}</Link>
                  })}</p>}
                </div>
            </div>
        </Styled.Modal> 
    )
  }
  
  export default Modal