import { useEffect, useState } from 'react'
import Logo from 'components/Logo'
import FeaturedKitties from 'components/FeaturedKitties'
import KittyHats from 'components/KittyHats'
import Search from 'components/Search'
import { history } from 'store'
import * as Styled from './Homepage.style'

const Homepage = ({ hats, allHatEvents, loggedIn, searchables, web3, socket, handleHatPurchase, handleHatPurchaseAndApply }) => {
	const [total, setTotal] = useState(undefined)
	const [kittyID, setKittyID] = useState('')

	useEffect(() => {
		const handleProgress = progress => setTotal(progress.Birth)
		if (socket) {
			socket.on('progress', handleProgress);
		}

		socket && socket.emit('getProgress')

		return () => {
			if (socket) {
				socket.off('progress', handleProgress);
			}
		};
	}, [socket])

	return (
		<Styled.Main>
			<Logo />
			<p>{'Ever wondered how your kitty came to be? Place a kitty id in the box to find out...'}</p>
			<Search
				{...{ kittyID }}
				onChange={e => setKittyID(e.target.value)}
				onSubmit={id => history.push(`/kitty/${id}`)}
				disabled={parseInt(kittyID) > total || kittyID === '' || !socket}
				max={parseInt(total)}
			/>
			<h4>{'🙀 Update!'} {'Kitty.Family now has a chat room - you just need to own at least one kitty to participate 🙀'}</h4>
			<h2>Featured Kitties</h2>
			<FeaturedKitties />
			<img src={'/images/kitty-hats/logo.png'} alt={'Kitty Hats'} />
			<h2>Kitty Hats</h2>
			<KittyHats 
				{...{ hats, allHatEvents, loggedIn, searchables, web3 }}
				handlePurchase={handleHatPurchase}
				handlePurchaseAndApply={handleHatPurchaseAndApply}
			/>
		</Styled.Main>
	)
}

export default Homepage
