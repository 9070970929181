import styled from 'styled-components'
import * as Styled from 'style'
import { fontSize, gutters } from 'style/config'

export const Div = styled.div`
    padding: ${gutters['xxl']} 0 0;
    display: flex;
    flex-direction: column;
    margin-bottom: ${gutters['md']};
    > img {
        width: 30px;
        margin: 0 auto ${gutters['xs']};
    }
    > h2 {
        width: 100%;
        text-align: center;
        margin-bottom: ${gutters['md']};
        font-size: ${fontSize['lg']};
    }
    > p {
        text-align: center;
        &:last-of-type {
            margin-bottom: ${gutters['lg']};
        }
        > a {
            border-bottom: 1px dotted #666;
            &:hover {
                text-decoration: none;
                border-bottom: 1px solid #333;
            }
        }
        
    }
    > div {
        display: flex;
        justify-content: space-between;
        margin: 0 5%;
        > div {
            font-size: ${fontSize['sm']};
            font-weight: bold;
            display: flex;
            align-items: center;
            > label {
                margin-right: ${gutters['xs']};
            }
        }
    }
    
`

export const Grid = styled(Styled.Grid)`
    padding-bottom: ${gutters['md']};
    width: 90%;
    margin: 0 5%;
    h3 {
        &:first-of-type {
            margin-top: ${gutters['sm']};
        }
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`