import axios from 'axios'
import { API_URL } from 'utils'

export const REQUEST_KITTIES = 'REQUEST_KITTIES'
export const RECEIVE_KITTIES = 'RECEIVE_KITTIES'
export const ERROR_KITTIES = 'ERROR_KITTIES'

let baseRequest = axios.create({ baseURL: `${process.env.REACT_APP_END_POINT}/cryptokitties/` })

export function fetchKitties() {
  return dispatch => {
    dispatch({ type: REQUEST_KITTIES })
    baseRequest.get('kitties?include=sale,sire,other&orderDirection=desc&limit=1').then(({ data }) =>
      dispatch({ type: RECEIVE_KITTIES, payload: data.kitties })
    ).catch(err => dispatch({ type: ERROR_KITTIES, payload: err }))
  }
}
