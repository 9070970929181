import { useEffect, useRef, useState } from 'react'
import logo from 'svg/logo.svg'
import axios from 'axios'
import KittiesWithSearch from 'components/KittiesWithSearch'
import KittyPfp from 'components/KittyPfp'
import Kitty from 'components/Kitty'
import SearchBar from 'components/SearchBar'
import Pagination from 'components/Pagination'
import { ReactComponent as Back } from 'svg/back.svg'
import { ReactComponent as Close } from 'svg/close.svg'
import paw from 'svg/paw.svg'
import paw2 from 'svg/paw2.svg'
import * as Styled from './Profile.style'
import {
    acceptedInputForType,
    acceptedInputForCooldown,
    acceptedInputForMewtation,
    includeTermWhitelist,
    isValidSearchTerm,
    isValidIncludeTerm,
    isNumber,
    isWithinRange,
    searchTermWhitelist,
    orderByWhitelist,
    orderDirectionWhitelist,
    makeUrl,
    makeApiUrl,
    LIMIT,
} from 'components/KittyHats/utils';

const { REACT_APP_END_POINT } = process.env

const Profile = ({ match: { params: { profile } }, searchables, loggedIn, token, handleSignIn, user, checkToken }) => {
    const [modal, setModal] = useState(false)
    const [profileInfo, setProfileInfo] = useState(undefined)
    const [results, setResults] = useState(undefined)
    const [hats, setHats] = useState(undefined)
    const [kitty, setKitty] = useState(undefined)
    const [follow, setFollow] = useState(false)
    const [followSuccess, setFollowSuccess] = useState(false)

    const modalOverlayRef = useRef(null)

    const handleOverlayClick = (event) => modalOverlayRef.current && event.target === modalOverlayRef.current && closeModal()
    const handleKeyDown = (event) => event.key === 'Escape' && closeModal() // Attach a keydown event listener to close the modal on pressing the "Escape" key

    const closeModal = () => {
        setKitty(undefined)
    }

    useEffect(() => {
        const followAttempt = async (user) => {
            try {
                const f = await axios.post(`${REACT_APP_END_POINT}/cryptokitties/auth/follow`, { token, profile })
                checkToken()
                console.log(f)
            } catch (e) {
                console.log(e)
            }
        }
        if (follow) {
            if (loggedIn) {
                followAttempt(profile)
            } else {
                handleSignIn()
            }
        }
    }, [follow])



    useEffect(() => {
        const fetchProfiles = async () => {
          if (!profileInfo) {
            try {
                const response = await axios.get(`${REACT_APP_END_POINT}/account?accounts=[${profile}]`)
                if (response.data[0].avatar > 0) {
                    const { data: { kitties } } = await axios.get(`${REACT_APP_END_POINT}/cryptokitties/kitties?search=id:${response.data[0].avatar}`)
                    const ids = kitties.map(({ tokenId }) => tokenId).join(',')
                    axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids}&limit=20`).then(async ckResult => {
                        const { data: { kitties: ckData } } = ckResult
                        const _kitties = kitties.map(kitty => {
                            return { ...ckData.find(ck => ck.id === kitty.tokenId), ...kitty };
                        });
                        const { data: { hats } } = await axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids}`)
                        setHats([...hats])
                        setResults(_kitties)
                        setProfileInfo(response.data[0])
                    })
                } else {
                    setProfileInfo(response.data[0])
                }
              } catch (error) {
                console.error('Error fetching profiles:', error)
              }
          }
        }
    
        fetchProfiles()
    }, [profile, profileInfo])

    useEffect(() => {
        if (profileInfo) {
            setProfileInfo(undefined)
            setResults(undefined)
            setHats(undefined)
        }
    }, [profile])
    
    useEffect(() => {
        if (modal || kitty) {
            document.body.style.overflow = 'hidden'
        } else {
            document.body.style.overflow = 'auto'
        }
        return () => {
            document.body.style.overflow = 'auto'
        }
    }, [modal, kitty])
    return (
        <>
            {modal && <Modal onClose={() => setModal(false)} {...{ loggedIn, searchables, token }} displayName={profileInfo.displayName ? profileInfo.displayName : ''} />}
            <Styled.Div>
                {profileInfo && (
                    <div>
                        {results && results.length > 0 ? (
                            <KittyPfp kitty={results[0]} hats={hats.filter(({ tokenId }) => tokenId === results[0].tokenId)} bgColor={results[0].color} size={'160px'} />
                        ) : (
                            <img src={logo} alt={''} className={'placeholder'} />
                        )}
                        {loggedIn === profile ? (
                            <p onClick={() => setModal(true)}>edit</p>
                        ) : (
                            user && user.following && user.following.includes(profile.toLowerCase()) ? (
                                <Styled.FollowC2A role={'button'} onClick={() => setFollow(profileInfo.address)}>{'Following'} <img src={paw} alt={''} /></Styled.FollowC2A>
                            ) : (
                                <Styled.FollowC2A role={'button'} onClick={() => setFollow(profileInfo.address)}>{'Follow'} <img src={paw2} alt={''} /></Styled.FollowC2A>
                            )   
                            
                        )}
                        <h3>{profileInfo.displayName ? profileInfo.displayName : profileInfo.address}</h3>
                        <h3>balance: {profileInfo.balance === null ? '0' : profileInfo.balance} - birthed: {profileInfo.birthed === null ? '0' : profileInfo.birthed}</h3>
                    </div>
                )}
                <KittiesWithSearch {...{ searchables, profile }} account={{ wallet: loggedIn }} />
            </Styled.Div>
        </>
        
    )
}

const Modal = ({ searchables, loggedIn, onClose, token, displayName }) => {
    const [total, setTotal] = useState(undefined)
    const [hats, setHats] = useState(undefined)
    const [selectedKitty, setSelectedKitty] = useState(false)
    const [results, setResults] = useState(undefined)
    const [search, setSearch] = useState({ include: { sale: true, sire: true, other: true }, page: 1, orderBy: 'age', orderDirection: 'asc', account: loggedIn, virgin: false })
    const [saveAvatar, setSaveAvatar] = useState(false)
    const [saveAvatarSuccess, setSaveAvatarSuccess] = useState(false)
    const [saveForm, setSaveForm] = useState(false)
    const [saveFormSuccess, setSaveFormSuccess] = useState(false)
    const [formInfo, setFormInfo] = useState({ displayName })

    const modalOverlayRef = useRef(null)

    useEffect(() => {
        const getKitties = async () => {
            modalOverlayRef.current && modalOverlayRef.current.firstChild.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
            const { data: { kitties, total } } = await axios.get(`${REACT_APP_END_POINT}/cryptokitties/kitties?${makeApiUrl(search)}`)
            const ids = kitties.map(({ tokenId }) => tokenId).join(',')
            axios.get(`https://api.cryptokitties.co/v3/kitties?search=id:${ids}&limit=20`).then(async ckResult => {
                const { data: { kitties: ckData } } = ckResult
                const _kitties = kitties.map(kitty => {
                    return { ...ckData.find(ck => ck.id === kitty.tokenId), ...kitty };
                });
                const { data: { hats } } = await axios.get(`${REACT_APP_END_POINT}/kitty-hats-events?search=id:${ids}`)
                setHats([...hats])
                setResults(_kitties)
                setTotal(total)
            })
        }
        if (loggedIn) {
            getKitties()
        }
    }, [loggedIn, search])

    const handleOverlayClick = (event) => modalOverlayRef.current && event.target === modalOverlayRef.current && onClose()
    const handleKeyDown = (event) => event.key === 'Escape' && onClose() // Attach a keydown event listener to close the modal on pressing the "Escape" key

    const specialTerms = ['fancy', 'exclusive', 'specialedition', 'shinyfancy']
    const addTerm = (description, sType) => {
        const _search = { ...search }
        _search['page'] = 1
        if (sType) {
            if (specialTerms.includes(sType)) {
                specialTerms.forEach((type) => {
                    if (_search[type] && sType !== _search[type]) delete _search[type]
                })
                _search.type = sType
                _search[sType] = description
                _search['mewtation'] && delete _search['mewtation']
                _search['terms'] && delete _search['terms']
                addSearch(_search)
            } else {
                if (searchTermWhitelist.includes(sType)) {
                    _search[sType] = description
                    addSearch(_search)
                } else {
                }
            }
        } else {
            const searchable = searchables.find((s) => s.description === description)
            if (searchable) {
                _search.terms = _search.terms || []
                delete _search['type']
                specialTerms.forEach((type) => {
                    delete _search[type]
                })
                const sameTypeTerms = searchables
                    .filter(({ type }) => type === searchable.type)
                    .map((s) => s.description)
                _search.terms = _search.terms.filter((term) => !sameTypeTerms.includes(term))
                _search.terms.push(description)
            }
            addSearch(_search)
        }
    }

    const formatSearchInputDisplay = (_search) =>
        formatTags(_search)
            .map((s) => {
                if (s === 'terms') {
                    return search[s].join(' ');
                } else {
                    if (!(s === 'pto' || s === 'pfrom' || (s === 'type' && search[s] === 'normal'))) {
                        return [...specialTerms, 'purrstige'].includes(s) ? `${search[s]}` : `${s}:${search[s]}`;
                    }
                }
                return undefined;
            })
            .filter((item) => item !== undefined)
            .join(' ');

    const formatTags = (_search) =>
        Object.keys(search).filter(
            (s) =>
                (searchTermWhitelist.includes(s) &&
                    !['virgin'].includes(s) &&
                    !(['account'].includes(s) && _search[s] === loggedIn)) ||
                s === 'terms',
        )

    const handleSearchSubmit = (searchQuery) => {
        const _search = { ...search }
        _search.terms = []
        const searchTerms = searchQuery.trim().split(/\s+/)
        searchTerms.forEach((term) => {
            const searchable = searchables.find((s) => s.description === term)
            if (searchable) {
                const sameTypeTerms = searchables
                    .filter(({ type }) => type === searchable.type)
                    .map((s) => s.description)
                if (specialTerms.includes(searchable.type)) {
                    const termType = searchable.type
                    _search[termType] = term
                } else {
                    _search.terms = _search.terms.filter((term) => !sameTypeTerms.includes(term))
                    _search.terms.push(term)
                }
                specialTerms.forEach((type) => {
                    delete _search[type]
                })
            } else {
                const [termType, termValue] = term.split(':')
                if (isValidSearchTerm(termType)) {
                    if (specialTerms.includes(termType)) {
                        specialTerms.forEach((type) => {
                            if (_search[type] && termType !== _search[type]) delete _search[type]
                        })
                        _search.type = termType
                        _search[termType] = termValue
                        _search['mewtation'] && delete _search['mewtation']
                        _search['terms'] && delete _search['terms']
                    } else {
                        if (searchTermWhitelist.includes(termType)) {
                            _search[termType] = termValue
                        }
                    }
                }
            }
        })
        addSearch(_search)
    }

    const handleSearchClear = () => {
        const _search = {}
        _search.include = search.include
        _search.orderBy = search.orderBy
        _search.orderDirection = search.orderDirection
        _search.virgin = search.virgin
        _search.page = 1
        if (search.account === loggedIn) {
            _search.account = search.account
        }
        addSearch(_search)
    }

    const addSearch = (_search) => setSearch(_search)

    useEffect(() => {
        document.addEventListener('click', handleOverlayClick)
        document.addEventListener('keydown', handleKeyDown)
        return () => {
            document.removeEventListener('click', handleOverlayClick);
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [onClose])

    useEffect(() => {
        if (saveAvatar && loggedIn) { // todo - maybe add logic that this isn't their existing avatar?
            const updateAvatar = async (tokenId) => {
                try {
                    const u = await axios.post(`${REACT_APP_END_POINT}/cryptokitties/auth/avatar`, { token, tokenId })
                    setSaveAvatarSuccess(true)
                } catch (e) {
                    console.log(e)
                }
            }
            updateAvatar(saveAvatar)
        }
    }, [saveAvatar])

    useEffect(() => {
        if (saveForm && loggedIn) { // todo - maybe add logic that this isn't their existing avatar?
            const updateInfo = async () => {
                const u = await axios.post(`${REACT_APP_END_POINT}/cryptokitties/auth/info`, { token, formInfo })
                setSaveFormSuccess(true)
            }
            updateInfo()
        }
    }, [saveForm, formInfo])

    return (
        <Styled.Modal ref={modalOverlayRef} wider={!selectedKitty}>
            <div>
                <Close onClick={onClose} />
                <Back onClick={() => setSelectedKitty(false)} />
                {selectedKitty ?
                    (
                        <Styled.SelectedKitty>
                            <KittyPfp kitty={selectedKitty} hats={hats.filter(({ tokenId }) => tokenId === selectedKitty.tokenId)} bgColor={selectedKitty.color} size={'160px'} />
                            <h3>#{selectedKitty.tokenId}{selectedKitty.name && ` - ${selectedKitty.name}`}</h3>
                            <p>Use the save button if you want to use this kitty as your PFP:</p>
                            <div className={saveAvatarSuccess ? 'success' : undefined}>
                                <button onClick={() => setSaveAvatar(selectedKitty.tokenId)} disabled={saveAvatar || saveAvatarSuccess ? true : false}>Save</button>
                            </div>
                            <hr />
                            <h3>Share some deets:</h3>
                            <input type={'text'} value={formInfo.displayName} placeholder={'Display name'} onChange={e => setFormInfo({ ...formInfo, displayName: e.target.value })} />
                            <div className={saveFormSuccess ? 'success' : undefined}>
                                <button onClick={() => setSaveForm(formInfo)} disabled={saveForm ? true : false}>Save</button>
                            </div>
                            <hr />
                            {/* <h3>GTFO...</h3>
                            <p>Use this button if you want kitty.family to remove all information stored on our databases about you</p>
                            <div className={saveAvatarSuccess ? 'success' : undefined}>
                                <button onClick={() => console.log('remove info')}>Remove all info</button>
                            </div>
                            
                            <hr /> */}
                        </Styled.SelectedKitty> 
                    ) : (
                        <>
                            <Styled.Grid>
                                <header>
                                    <h3>Select a Kitty to use as your kitty.family pfp:</h3>

                                    <SearchBar
                                        suggestions={searchables}
                                        searchQuery={formatSearchInputDisplay(search)}
                                        onSearchSuggestionSelect={({ description }) => addTerm(description)}
                                        onSearchSubmit={handleSearchSubmit}
                                        onSearchClear={handleSearchClear}
                                        disabled={!search}
                                        {...{ location }}
                                    />
                                </header>
                                {results && hats && results.map((kitty, i) =>
                                    <Kitty key={i} {...{ kitty }}
                                        hats={[...hats.filter(hat => hat.tokenId === kitty.tokenId)]}
                                        c2aPosition={'top'}
                                        showMewts={true}
                                        showInfo={false} bgColor={kitty.color} getInfo={() => setSelectedKitty(kitty)}
                                    />
                                )}
                                {total && <Pagination
                                    showPages
                                    currentPage={search.page}
                                    totalPages={Math.ceil(total / 20)}
                                    maxPages={10}
                                    onChange={page => setSearch({ ...search, page })}
                                />}
                            </Styled.Grid>


                        </>
                    )
                }
            </div>
        </Styled.Modal>
    )
}

export default Profile