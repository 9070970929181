import styled from 'styled-components'
import * as Styled from 'style'
import { gutters, fontSize } from 'style/config'

export const Div = styled.div`
    padding: ${gutters['xxl']} 0;
    display: flex;
    flex-direction: column;
    margin-bottom: ${gutters['md']};
    > img {
        width: 30px;
        margin: 0 auto ${gutters['xs']};
    }
    > h2 {
        width: 100%;
        text-align: center;
        margin-bottom: ${gutters['lg']};
        font-size: ${fontSize['lg']};
    }
    > p {
        text-align: center;
        font-size: ${fontSize['md']};
        &:last-of-type {
            margin-bottom: ${gutters['xl']};
        }
    }
}`
