import React, { useState } from 'react'
import Message from 'components/Message'
import Messages from 'components/Messages'
import Menu from 'components/Menu'
import * as Styled from './Chat.style'

const Chat = ({ loggedIn, socket, user, catsWithHats }) => {
    const [open, setOpen] = useState(false)
    const toggleDiv = () => setOpen(!open)
    return (
        <>
            <Menu handleClick={toggleDiv} {...{ loggedIn, catsWithHats }} />
            <Styled.Div {...{ open }}>
                <header>
                    <h2>#KittyChat</h2>
                    <div onClick={toggleDiv}>X</div>
                </header>
                <Message {...{ loggedIn, socket, user }} />
                <Messages assets={[]} {...{ socket }} />
            </Styled.Div>
        </>
        
    )
}

export default Chat